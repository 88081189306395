






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BSpinner } from 'bootstrap-vue'
import QrcodeVue from 'qrcode.vue'

@Component({ components: { BSpinner, QrcodeVue } })
export default class NalunchWait extends Vue {
  @Prop() qrContent: string | null = null;
}
